<script setup>
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline';
import { onMounted, ref } from 'vue';

const model = defineModel({ type: String });

const input = ref(null);
const type = ref('password');

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <div class="relative flex">
        <input
            ref="input"
            v-model="model"
            :type="type"
            class="w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-400 focus:outline-0 focus:ring focus:ring-emerald-500/20 sm:text-sm"
        />
        <div
            class="group absolute right-0 top-0 flex h-full w-10 cursor-pointer items-center justify-center"
            @click="type = type === 'password' ? 'text' : 'password'"
        >
            <EyeIcon
                v-if="type === 'password'"
                class="h-4 text-gray-400 group-hover:text-gray-600"
            />
            <EyeSlashIcon v-else class="h-4 text-gray-400 group-hover:text-gray-600" />
        </div>
    </div>
</template>
